@import "olympus-frontend/src/@core/scss/base/bootstrap-extended/_variables.scss";

.select__option--is-disabled{
    cursor: not-allowed !important;
    opacity: 0.25 !important;
}

.tooltip-mine{
    border-radius: 3px;
    box-shadow: 0 3px 8px 0 rgba(11,10,25,.49);
    font-family: 'Rubik', Arial, sans-serif;
    padding:10px;
}

.arrow {
    pointer-events: none;
}

.arrow__path {
    stroke: $primary;
    fill: transparent;
    stroke-width: 2px;
}

.empty_row_layout{
    border-bottom: 1px solid #676a6c;
}



.tooltip-inner{
    text-align: left;
    width: 520px;

    .tooltip-header {

    }
    .tooltip-body{
        display:flex;
        align-items:start;
        flex-direction: column;
    }

    .panel-info, .micro-info, .load-info {
        display:flex;
        flex-direction: column;
        text-align: left;
        padding: 5px 0px;
    }


}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.k-numerictextbox .k-numeric-wrap .k-select {
    display:none !important;
}

.k-textbox:focus, .k-numerictextbox:focus {
    background-color: inherit !important;
    box-shadow: none !important;
}
